<template>
  <div class="flex flex-col">
    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
        <table class="min-w-full">
          <thead>
            <tr>
              <th class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-600 uppercase bg-gray-200 border-b border-gray-200">
                Team
              </th>
              <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-center text-gray-600 uppercase bg-gray-200 border-b border-gray-200">
                Members
              </th>
              <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-center text-gray-600 uppercase bg-gray-200 border-b border-gray-200">
                Projects
              </th>
              <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-center text-gray-600 uppercase bg-gray-200 border-b border-gray-200">
                Plan
              </th>
              <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-center text-gray-600 uppercase bg-gray-200 border-b border-gray-200">
                Role
              </th>
              <th class="px-6 py-3 bg-gray-200 border-b border-gray-200" />
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr
              v-for="team in teams"
              :key="team.id"
            >
              <td class="py-4 pl-4 border-b border-gray-200 whitespace-nowrap">
                <div class="flex items-center">
                  <div
                    class="text-sm font-medium leading-5 text-gray-900 border-b-2 border-gray-300 cursor-pointer hover:border-gray-500"
                    @click.prevent="toTeam(team)"
                  >
                    {{ team.name }}
                  </div>
                </div>
              </td>
              <td class="py-4 pl-3 pr-1 text-sm text-center border-b border-gray-200 whitespace-nowrap">
                {{ team.members_count }} / <span class="text-gray-600">25</span>
              </td>

              <td class="py-4 pl-3 pr-1 text-sm text-center border-b border-gray-200 whitespace-nowrap">
                {{ team.projects_count }} / <span class="text-gray-600">100</span>
              </td>

              <td class="px-1 py-4 text-center border-b border-gray-200 whitespace-nowrap">
                <TeamTierBadge :tier="team.team_tier" />
              </td>
              <td class="px-1 py-4 text-sm leading-5 text-center text-gray-500 capitalize border-b border-gray-200 whitespace-nowrap">
                <span class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                  {{ team.team_role }}
                </span>
              </td>
              <td class="py-4 pr-4 text-sm font-medium leading-5 text-right border-b border-gray-200 whitespace-nowrap">
                <a
                  href="#"
                  class="text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
                  @click.prevent="onLeave(team)"
                >Leave</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
const TeamLeaveModal = () => import('@modals/TeamLeave')
const TeamTierBadge = () => import('@components/badges/TeamTierBadge')

export default {
  name: 'ProfileAccess',

  components: { TeamTierBadge },

  computed: {

    teams () {
      return this.$store.getters['teams/teams']
    },

    user () {
      return this.$store.getters.user
    }
  },

  methods: {

    toTeam (team) {
      this.$router.push('/team/' + team.slug)
      this.$emit('close')
    },

    onLeave (team) {
      this.$modal.show(
        TeamLeaveModal, {
          team: team,
          user: this.user
        },
        {
          height: 'auto',
          width: '400px',
          scrollable: true
        })
    },

    memberCount (members) {
      let fullmembers = members.filter(function (member) {
        return member.team_role !== 'guest_full' && member.team_role !== 'guest_limited'
      })
      return fullmembers.length
    },

    guestCount (members) {
      let guestmembers = members.filter(function (member) {
        return member.team_role === 'guest_full' || member.team_role === 'guest_limited'
      })

      return guestmembers.length
    }

  }
}
</script>
